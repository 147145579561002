require('./bootstrap');


function format_nominal(number, decimals, dec_point, thousands_sep) {
    number = parseFloat(number);
    if (isNaN(number) || !isFinite(number)) {
        return 'NaN';
    }

    decimals = decimals || 0;
    dec_point = dec_point || '.';
    thousands_sep = thousands_sep || ',';

    var sign = number < 0 ? '-' : '';
    number = Math.abs(number).toFixed(decimals);

    var parts = number.split('.');
    var integerPart = parts[0];
    var decimalPart = parts.length > 1 ? dec_point + parts[1] : '';

    var pattern = /(\d+)(\d{3})/;
    while (pattern.test(integerPart)) {
        integerPart = integerPart.replace(pattern, '$1' + thousands_sep + '$2');
    }

    return sign + integerPart + decimalPart;
}